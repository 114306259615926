/* this file is transformed by vux-loader */
/* eslint-disable */
import _createForOfIteratorHelper from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Cell from "vux/src/components/cell/index.vue";
import Datetime from "vux/src/components/datetime/index.vue";
import Group from "vux/src/components/group/index.vue";
import Tab from "vux/src/components/tab/tab.vue";
import TabItem from "vux/src/components/tab/tab-item.vue";
import ViewBox from "vux/src/components/view-box/index.vue";
import Scroll from '@/components/Scroll/Scroll';
export default {
  name: "RepairAppList",
  components: {
    ViewBox: ViewBox,
    Tab: Tab,
    TabItem: TabItem,
    Group: Group,
    Cell: Cell,
    Datetime: Datetime,
    Scroll: Scroll
  },
  data: function data() {
    return {
      infoList: [],
      month: '',
      year: '',
      start: '',
      end: '',
      currDate: '',
      pullDownRefresh: {
        threshold: 40,
        // 下拉多少开始刷新
        stop: 20 // 下拉回弹到多少停住

      },
      pullUpLoad: {
        threshold: 25
      },
      isPullDown: false,
      // 正在下拉刷新
      isPullUp: false,
      // 正在上拉加载
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      emptyShow: false,
      repairState: this.$utils.Store.getItem("repairApp") || '0',
      sumInfo: [],
      running: 0,
      finished: 0
    };
  },
  computed: {},
  methods: {
    queryData: function queryData(id) {
      this.$router.push({
        name: 'RepairAppInfo',
        params: {
          state: id,
          index: this.repairState
        }
      });
    },
    changeDate: function changeDate(date) {
      this.infoList = [];
      this.month = this.$utils.DateUtils.getMonthMM(date + "-01");
      this.year = this.$utils.DateUtils.getYear(date + "-01");
      this.start = this.$utils.DateUtils.getMonthStart(date + "-01");
      this.end = this.$utils.DateUtils.getNextMonthStart(date + "-01", 1);
      this.getInfoList();
      this.getSumInfo();
    },
    onPullingDown: function onPullingDown() {
      if (this.isPullDown) return;
      this.isPullDown = true; // 下拉刷新

      this.infoList = [];
      this.pageIndex = 1;
      this.getInfoList();
    },
    onPullingUp: function onPullingUp() {
      this.pageIndex = this.pageIndex + 1;

      if (this.pageIndex > this.totalPage) {
        this.updateScroll();
        return;
      }

      if (this.isPullUp) return;
      this.isPullUp = true;
      this.getInfoList();
    },
    updateScroll: function updateScroll(flag) {
      this.isPullDown = false;
      this.isPullUp = false;
      this.$refs.scroll.forceUpdate(flag);
    },
    getSysDate: function getSysDate() {
      var _this = this;

      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.currDate = data.sysDate;
          _this.start = _this.$utils.DateUtils.getMonthStart(data.sysDate);
          _this.end = _this.$utils.DateUtils.getNextMonthStart(data.sysDate, 1);
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    getInfoList: function getInfoList() {
      var _this2 = this;

      this.$utils.Tools.loadShow();
      this.emptyShow = false;
      this.$api.Repairs.selectListPageByEntity({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        search: {
          repairUser: this.$utils.Store.getItem("userSerial"),
          repairState: this.repairState,
          start: this.start,
          end: this.end
        }
      }).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);

          _this2.updateScroll(false);
        } else {
          var _this2$infoList;

          (_this2$infoList = _this2.infoList).push.apply(_this2$infoList, _toConsumableArray(data.records));

          _this2.pageIndex = data.pageIndex;
          _this2.totalPage = data.totalPage;

          if (_this2.infoList.length === 0) {
            _this2.emptyShow = true;
          }

          _this2.updateScroll(false);
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    getSumInfo: function getSumInfo() {
      var _this3 = this;

      this.running = 0;
      this.finished = 0;
      this.$api.Repairs.selectSumInfoByEntity({
        repairUser: this.$utils.Store.getItem("userSerial"),
        start: this.start,
        end: this.end
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);
        } else {
          var _iterator = _createForOfIteratorHelper(data),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var item = _step.value;

              if (item.repairState === '0') {
                _this3.running = item.num;
              }

              if (item.repairState === '1') {
                _this3.finished = item.num;
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },
    recordClick: function recordClick() {
      this.$router.push({
        name: 'RepairAppInfo'
      });
    },
    onItemClick: function onItemClick(index) {
      this.infoList = [];
      this.repairState = index;
      this.getInfoList();
    },
    submitData: function submitData() {
      this.$router.push({
        name: 'RepairInfo'
      });
    }
  },
  created: function created() {
    this.getSysDate();
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {
    this.$utils.Store.removeItem("repairApp");
  }
};